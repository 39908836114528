import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import {
  AltPanels,
  InstagramList,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.KATHMANDU_PROMO.TITLE,
    absolute_slug: PAGES.KATHMANDU_PROMO.PATH,
  },
];

const KathPromoPage = () => (

  <Layout page="about/kathmandu-snow-promotion">

    <Helmet
      title="Snow Promotion | New Zealand Snow Tours | Haka Tours"
      meta={[
        {
          name: 'description', content: 'The snow season is fast approaching, so to get you ready for your next snow adventure, we’re giving away 50 x $200 Kathmandu Vouchers.',
        },
        { name: 'og:title', content: 'Snow Promotion | New Zealand Snow Tours | Haka Tours' },
        {
          name: 'og:description', content: 'The snow season is fast approaching, so to get you ready for your next snow adventure, we’re giving away 50 x $200 Kathmandu Vouchers.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/kathmandu-snow-promotion/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/snow-final-1.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Snow Promo</h2>
    </Banner>
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h2">Book your Haka Snow Tour & receive a $200 Kathmandu Voucher.</h1>
      </div>
    </section>
    <AltPanels items={[
      {
        title: 'How to get your voucher',
        content: `<p>We’ve partnered with Australasia’s largest outdoor adventure retailer to give away <b>50 x $200 Kathmandu Vouchers.</b><br /><br />
        ** Book any Haka Snow Tour before 31 May and mention promocode <u><b>KATHMANDU200</b></u> in the special requests box at time of booking to recieve your $200NZD voucher.<br /><br />
        With only 50 vouchers up for grabs,  book today so you don’t miss out!
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/kath-promo-1.jpg?auto=format',
          align: '',
        },
      },
      {
        title: 'Your Snow Gear List',
        content: `<p>Kathmandu have been designing, testing and adapting their gear to New Zealand’s environment since 1987,
        so grab the deal below and jump in to any of their 167 stores across Australia and New Zealand, and kit yourself out for less.<br /><br />
        Put your $200 voucher towards a thermal layer, ski jacket or new snow goggles and you’ll be that much better prepared for your epic snow adventure with Haka Tours this season.
        <a class="link" href="https://s3-ap-southeast-2.amazonaws.com/files.hakatours.com/Haka-Kathmandu-Snow-Gear-List.pdf" target="_blank">Download our Gear List</a>
        and check out Kathmandu's full range at <a class="link" href="http://kathmandu.co.nz/ski-snow" target="_blank">kathmandu.co.nz/ski-snow</a>
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/kath-promo-6.jpg?auto=format',
          align: '',
        },
      },
      {
        title: 'Find your tour',
        content: `<p><a class="link" href="https://hakatours.com/new-zealand-snow-tours/south-island-snow-safari" target="_blank">Snow Island Snow Safari (7 days $1,799)</a> <br />
        <a class="link" href="https://hakatours.com/new-zealand-snow-tours/south-island-heliski-tour" target="_blank">Ultimate Heli Tour (7 days from $5,999)</a> <br />
        <a class="link" href="https://hakatours.com/new-zealand-snow-tours/snow-explorer" target="_blank">Snow Explorer (5 days from $1,499)</a> <br />
        <a class="link" href="https://hakatours.com/new-zealand-snow-tours/south-island-club-fields" target="_blank">South Island Snow Odyssey (12 days from $2,999)</a> <br />
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/kath-promo-5.jpg?auto=format',
          align: '',
        },
      },
    ]}
    />
    <section className="l-section condition-section">
      <div className="l-container condition-text">
        *Conditions: Offer applies to new direct bookings only, made between 03-31 May 2019 for any Haka NZ Snow Tour departing in 2019.
        NZD$200 voucher will be secured upon payment of your $99 tour deposit and evoucher will be emailed within 14 days of your full tour payment.
        Only 50 vouchers available and will be distributed on a first come first served basis. Mention promo code KATHMANDU200 in the special requests box at time of booking.
        You can redeem your voucher at any of Kathmandu’s 47 New Zealand stores, or if you live in Australia,
        you can request your voucher to be issued in the equivalent Australian dollar amount (determined by exchange rate calculated
        on <a className="link" href="https://www.xe.com/" target="_blank" rel="noopener noreferrer">XE.com</a> at time of issuing),
        to allow you to redeem it at one of Kathmandu’s 120 Australian stores. Standard tour conditions apply and voucher cannot be used in conjunction with any other special offer.
      </div>
    </section>

    <InstagramList
      title="PURE NEW ZEALAND POWDER"
      subTitle="Want to be featured here? Simply tag your Insta pics with #hakatours or upload your pictures <a href='https://upload.crowdriff.com/haka-tours-guest-images' target='_blank'>here</a>."
      hash="22ba37036cccebf7"
    />
  </Layout>
);

export default KathPromoPage;
